import $ from 'jquery'

$(function () {
  $('.listing-related-notices').not('.card-initialized').slick({
    dots: true,
    slidesToShow: 2,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  });
});

document.addEventListener('DOMContentLoaded', () => {
  const shareButtons = document.querySelectorAll('.share-block, .mb-share-block')
  const socialShareOverlay = document.getElementById('social-share-overlay')
  const shareURL = document.querySelector('.share-link span')
  const copyLinkButton = socialShareOverlay?.querySelector('.share-link-copy-button')
  const linkCopiedToast = socialShareOverlay?.querySelector('.link-copied-toast')

  if (shareURL) shareURL.textContent = window.location.href

  shareButtons.forEach(shareButton => {
    if (!sessionStorage.getItem('shareBlockShakeAnimationPlayed')) {
      shareButton.classList.add('share-block-shake-animation')
      setTimeout(() => sessionStorage.setItem('shareBlockShakeAnimationPlayed', 'true'), 30000)
    }

    shareButton.addEventListener('click', () => {
      socialShareOverlay.classList.add('active')
      shareButton.classList.remove('share-block-shake-animation')
      sessionStorage.setItem('shareBlockShakeAnimationPlayed', 'true')
    })
  })

  socialShareOverlay?.addEventListener('click', (event) => {
    if (event.target === socialShareOverlay || event.target.closest('.close-icon')) {
      socialShareOverlay.classList.remove('active')
    }
  })

  copyLinkButton?.addEventListener('click', () => {
    navigator.clipboard.writeText(window.location.href).then(() => {
      linkCopiedToast.classList.add('active')
      setTimeout(() => linkCopiedToast.classList.remove('active'), 1000);
    })
  })


  const addToCalendarButton = document.querySelector('.add-to-calendar-button')
  const addToCalendarOverlay = document.getElementById('add-to-calendar-overlay')

  addToCalendarButton?.addEventListener('click', () => {
    addToCalendarOverlay.classList.add('active')
  })

  addToCalendarOverlay?.addEventListener('click', (event) => {
    if (event.target === addToCalendarOverlay || event.target.closest('.calendar-close-icon')) {
      addToCalendarOverlay.classList.remove('active')
    }
  })
})
