import axios from 'axios';

import "core-js/stable"
import dompolyfill from '~/common/js/modules/dom-polyfill.js';

import rnn from '../../common/js/rnn'

import Ads from '~/common/js/ads.js';
import Header from './header.js';
import PublicationSelector from './PublicationSelector.jsx';
import RefineSearch from '~/common/js/modules/refine-filters';
import attachOnSelectChangeUrlHandler from './search-result-sort.js';
import './home.js';
import './listing-details.js';
import './footer.js';
import './slick.js';
import '~/common/js/modules/modal'
import ImageGalleries from './image-gallery.js';
import './animation.js';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';// import a plugin
import './myaccount.js'
import RecentNoticesWidget from './recent-notices';
import './fd-listing-map.js';
import './funeral_listing.js';
import './refine-search-filter.js';
import DatePicker from './date-picker.js';
import ObituariesSearch from './obituaries-search.js';
import makeElementScroll from './funeral-directors';
import ArticlesSearch from './articles-search.js';
import '../images/mt-new-logo.svg';
import '../images/notices_submenu_image.jpg';
import '../images/articles_submenu_image.jpg';
import '../images/obituaries_submenu_image.jpg';
import '../images/search.svg';
import '../images/copy-icon.svg';
import '../images/share-icon.svg';
import '../images/search-thin.svg';
import '../images/facebook.svg';
import '../images/nca-horizontal-rgb-mono.png';
import '../images/phone.svg';
import '../images/question-mark.svg';
import '../images/map-pin.svg';
import '../images/google-map-pin.svg';
import '../images/google-map-pin-clicked.svg';
import '../images/icon-ionic-ios-arrow-down.svg';
import '../images/cairns-post.svg';
import '../images/mosman-daily.svg';
import '../images/mercury.svg';
import '../images/courier-mail.svg';
import '../images/daily-telegraph.svg';
import '../images/geelong-advertiser.svg';
import '../images/gold-coast-bulletin.svg';
import '../images/herald-sun.svg';
import '../images/northshore-times.svg';
import '../images/townsville-bulletin.svg';
import '../images/weekly-times.svg';
import '../images/wentworth-courier.svg';
import '../images/nt-news.svg';
import '../images/the-advertiser.svg';
import '../images/the-chronicle.svg';
import '../images/calendar.svg';
import '../images/fallback1.jpg'
import '../images/fallback2.jpg'
import '../images/fallback3.jpg'
import '../images/fallback4.jpg'
import '../images/fallback5.jpg'
import '../images/fallback6.jpg'
import '../images/fallback7.jpg'
import '../images/fallback8.jpg'
import '../images/fallback9.jpg'
import '../images/fallback10.jpg'
import '../images/fallback11.jpg'
import '../images/fallback12.jpg'
import '../images/fallback13.jpg'
import '../images/fallback14.jpg'
import '../images/fallback15.jpg'
import '../images/fallback16.jpg'
import '../images/fallback17.jpg'
import '../images/fallback18.jpg'
import '../images/advice-support.jpg';
import '../images/group-advice.jpg';

import '../images/MyTributes_logo_white.svg';
import '../images/modal_background.jpg';
import '../images/favicon.svg';
import '../images/favicon-32x32.png';
import '../images/apple-touch-icon.png';

import '../images/calendar-filter-button-mobile.svg';
import '../images/calendar-filter-button.svg';
import '../images/atoz-button.svg';
import '../images/ztoa-button.svg';
import '../images/listing-publication.svg';
import '../images/filter-date.svg';
import '../images/gallery-icon.svg';
import '../images/cart-icon.svg';
import '../images/afda.png';
import '../images/icon-clear.png';
import '../images/interflora.png';
import LightCandle from './light-candle.js';
import '../js/fd-recent-notices-carousel'
import menu from './menu.js';
import {
  SHOW_REFINEMENT_CLASS,
  refineEl,
  drawerEl,
  refineCloseEl,
  searchInputEl,
  formExpandEl,
  mobileSearchTitleEl,
  mobileSearchInputEl,
  closeMobileFormEl,
  searchFormEl,
  searchIconEl,
  documentBodyEl,
  startDateEls,
  header,
  obPlaceholder,
  whitelabels,
  funeralDirectorsEl,
  articlesSearchFilters
} from './constants.js';

// Global Modules
import u from 'umbrellajs'; // https://umbrellajs.com - jQuery 'lite' syntax
import Cookies from 'js-cookie';
window.Cookies = Cookies;

dompolyfill();
window.rnn = rnn

// Global Config
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

document.addEventListener("DOMContentLoaded", function () {
  Ads();
  rnn.modules.user.checkLoginUser(true);
  startDateEls.forEach(function (startDateEl, _) {
    new DatePicker(startDateEl);
  });
  RecentNoticesWidget(window.recentNotices || [], false);
  // Notice Widget for mobile devices
  RecentNoticesWidget(window.recentNotices || [], true);
  new Header(searchInputEl, formExpandEl, mobileSearchTitleEl, mobileSearchInputEl, closeMobileFormEl, searchFormEl, searchIconEl, documentBodyEl);
  new RefineSearch(refineEl, refineCloseEl, drawerEl, SHOW_REFINEMENT_CLASS);
  var obituariesSearch = new ObituariesSearch(header, whitelabels, obPlaceholder, documentBodyEl);
  var articlesSearch = new ArticlesSearch(header, whitelabels, articlesSearchFilters, documentBodyEl);
  attachOnSelectChangeUrlHandler('[data-interaction-sort]');
  attachOnSelectChangeUrlHandler('[data-interaction-per-page]');
  makeElementScroll(funeralDirectorsEl);
  PublicationSelector();
  ImageGalleries();
  new LightCandle();
  menu(window.menu_dict || []);
  // Remove preload class to start css animations at the same time
  document.querySelectorAll('.preload').forEach(x => x.classList.remove('preload'));

  // Open external links in a new tab and add 'nofollow'
  const url = window.location.pathname
  const last = url.split("/")
  const adID = (last[last.length - 2])
  u('#sanitised-text a')
    .filter(link => link.hostname !== location.hostname)
    .each(link =>
      u(link)
        .attr('target', '_blank')
        .attr('rel', 'nofollow')
        .attr('data-omniture-tracking', JSON.stringify({ clickStoreEvar: true, eVar: 'eVar23', value: adID + ' - ' + link.href }))
    )
});

// Hot Module reloading
if (typeof module !== 'undefined' && module.hot) {
  module.hot.accept('./main', function () {
    console.log('----------- main.js updated -----------')
    main()
  })
  // Capture and Re-insert siteconfig on hot reload
  let siteConfig;
  const _init = rnn.init
  window.rnn.init = cfg => siteConfig = cfg && _init(cfg)
  module.hot.accept('../../common/js/rnn', function () {
    console.log('----------- rnn.js updated -----------', siteConfig)
    window.rnn.init(siteConfig)
  })
}
