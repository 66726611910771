document.addEventListener('DOMContentLoaded', () => {
  // For login details form
  const loginDetailsForm = document.querySelector('.myaccount-login-details-form')
  const loginDetailsButtons = document.querySelector('.myaccount-login-details-buttons')
  const loginDetailsEditButton = loginDetailsButtons?.querySelector('.edit-button')
  const loginDetailsCancelButton = loginDetailsButtons?.querySelector('.cancel-button')
  const passwordInputfields = document.querySelectorAll('.myaccount-login-details-form .oldpassword-inputfield input, .myaccount-login-details-form .newpassword-inputfield input, .myaccount-login-details-form .confirmpassword-inputfield input')

  loginDetailsEditButton?.addEventListener('click', () => {
    loginDetailsForm.classList.add('active')
    loginDetailsButtons.classList.add('active')
  })

  loginDetailsCancelButton?.addEventListener('click', () => {
    loginDetailsForm.classList.remove('active')
    loginDetailsButtons.classList.remove('active')
    passwordInputfields.forEach((inputfield) => inputfield.value = '')
  })


  // For personal details form
  const personalDetailsForm = document.querySelector('.myaccount-personal-details-form')
  const personalDetailsButtons = document.querySelector('.myaccount-personal-details-buttons')
  const personalDetailsEditButton = personalDetailsButtons?.querySelector('.edit-button')
  const personalDetailsCancelButton = personalDetailsButtons?.querySelector('.cancel-button')

  const firstNameInputfield = document.querySelector('.myaccount-personal-details-form .first-name-inputfield input')
  const lastNameInputfield = document.querySelector('.myaccount-personal-details-form .last-name-inputfield input')
  const originalFirstName = firstNameInputfield?.value
  const originalLastName = lastNameInputfield?.value

  personalDetailsEditButton?.addEventListener('click', () => {
    personalDetailsForm.classList.add('active')
    personalDetailsButtons.classList.add('active')
    firstNameInputfield.removeAttribute('readonly')
    lastNameInputfield.removeAttribute('readonly')
  })

  personalDetailsCancelButton?.addEventListener('click', () => {
    personalDetailsForm.classList.remove('active')
    personalDetailsButtons.classList.remove('active')
    firstNameInputfield.value = originalFirstName
    lastNameInputfield.value = originalLastName
    firstNameInputfield.setAttribute('readonly', true)
    lastNameInputfield.setAttribute('readonly', true)
  })
})